import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  FormControlLabel,
  Grid,
  Avatar,
  Card,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { getDocs, collection } from "firebase/firestore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { db } from "../firebase";
import { useAppBarContext } from "../components/AppBarContext";
import { rarities, currencySymbols, inkColorMap } from "./constants";
import CaseBoxCalculator from "./CaseBoxCalculator";
import CaseCalculator from "./CaseCalculator";

const BoosterPackCalculator = () => {
  const { selectedCountry, exchangeRates } = useAppBarContext();
  const [sets, setSets] = useState([]);
  const [selectedSet, setSelectedSet] = useState("");
  const [selectedSetName, setSelectedSetName] = useState("");
  const [cards, setCards] = useState([]);
  const [highestPack, setHighestPack] = useState([]);
  const [lowestPack, setLowestPack] = useState([]);
  const [highestFoilCard, setHighestFoilCard] = useState(null);
  const [lowestFoilCard, setLowestFoilCard] = useState(null);
  const [includeEnchanted, setIncludeEnchanted] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [combinedHighestPack, setCombinedHighestPack] = useState([]);
  const [combinedLowestPack, setCombinedLowestPack] = useState([]);

  // Convert price based on selected currency
  const convertPrice = (usdPrice) => {
    if (!exchangeRates[selectedCountry]) return usdPrice;
    return `${selectedCountry} ${currencySymbols[selectedCountry]}${(usdPrice * exchangeRates[selectedCountry]).toFixed(2)}`;
  };

  // Fetch all available sets from Firestore
  useEffect(() => {
    const fetchSets = async () => {
      const setsSnapshot = await getDocs(collection(db, "sets"));
      const fetchedSets = setsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSets(fetchedSets);

      // Set default selected set name if a set is selected initially
      if (fetchedSets.length > 0) {
        const defaultSet = fetchedSets[0];
        setSelectedSet(defaultSet.id);
        setSelectedSetName(defaultSet.name);
      }
    };
    fetchSets();
  }, []);

  // Fetch cards for the selected set
  useEffect(() => {
    const fetchCardsForSet = async () => {
      if (!selectedSet) return;
      const cardsSnapshot = await getDocs(collection(db, "sets", selectedSet, "cards"));
      const fetchedCards = cardsSnapshot.docs.map((doc) => ({
        docName: doc.id,
        ...doc.data(),
      }));
      setCards(fetchedCards);
    };
    fetchCardsForSet();
  }, [selectedSet]);

  // Calculate the pack (6 Common, 3 Uncommon, 2 Rare/Super Rare/Legendary)
  const calculatePack = useCallback(
    (isHighestValue) => {
      if (cards.length === 0) return [];

      const sortedCards = [...cards].sort((a, b) =>
        isHighestValue ? (b.price || 0) - (a.price || 0) : (a.price || 0) - (b.price || 0)
      );

      const usedCardNames = new Set();
      const boosterPack = [];

      const selectCards = (count, rarityFilter) => {
        const filteredCards = sortedCards.filter(
          (card) => rarityFilter.includes(card.rarity) && card.type !== "Foil"
        );
        for (let i = 0; i < count; i++) {
          const card = filteredCards.find((c) => !usedCardNames.has(c.name));
          if (card) {
            boosterPack.push(card);
            usedCardNames.add(card.name);
          }
        }
      };

      // Select cards
      selectCards(2, ["Rare", "Super_rare", "Legendary"]);
      selectCards(6, ["Common"]);
      selectCards(3, ["Uncommon"]);

      return boosterPack;
    },
    [cards]
  );


  const calculateFoilCard = useCallback(
    (isHighestValue) => {
      const enchantedFoilCards = includeEnchanted
        ? cards.filter((card) => card.rarity === "Enchanted" && card.type === "Foil")
        : [];

      const foilCards = cards.filter(
        (card) => card.type === "Foil" && (includeEnchanted || card.rarity !== "Enchanted")
      );

      const sortedFoils = foilCards.sort((a, b) =>
        isHighestValue ? (b.price || 0) - (a.price || 0) : (a.price || 0) - (b.price || 0)
      );

      if (!isHighestValue && enchantedFoilCards.length > 0) {
        const sortedEnchantedFoils = enchantedFoilCards.sort(
          (a, b) => (a.price || 0) - (b.price || 0)
        );
        if (sortedEnchantedFoils[0].price < (sortedFoils[0]?.price || Infinity)) {
          return sortedEnchantedFoils[0];
        }
      }

      return sortedFoils[0] || null;
    },
    [cards, includeEnchanted]
  );

  

  useEffect(() => {
    const highestPack = calculatePack(true);
    const lowestPack = calculatePack(false);

    let highestFoil = calculateFoilCard(true);
    let lowestFoil = calculateFoilCard(false);

    if (includeEnchanted) {
      const enchantedFoilCards = cards.filter(
        (card) => card.rarity === "Enchanted" && card.type === "Foil"
      );

      if (enchantedFoilCards.length > 0) {
        const lowestEnchantedFoil = enchantedFoilCards.sort(
          (a, b) => (a.price || 0) - (b.price || 0)
        )[0];

        if (lowestEnchantedFoil) {
          lowestFoil = lowestEnchantedFoil;
        }
      }
    }

    const combinedHighest = [...highestPack];
    const combinedLowest = [...lowestPack];

    if (highestFoil) {
      const uniqueDocNames = new Set(combinedHighest.map((card) => card.docName));
      if (!uniqueDocNames.has(highestFoil.docName)) {
        combinedHighest.push(highestFoil);
      }
    }

    if (lowestFoil) {
      const uniqueDocNames = new Set(combinedLowest.map((card) => card.docName));
      if (!uniqueDocNames.has(lowestFoil.docName)) {
        combinedLowest.push(lowestFoil);
      }
    }

    setHighestPack(highestPack);
    setLowestPack(lowestPack);
    setCombinedHighestPack(combinedHighest);
    setCombinedLowestPack(combinedLowest);

    setHighestFoilCard(highestFoil);
    setLowestFoilCard(lowestFoil);
  }, [calculatePack, calculateFoilCard, includeEnchanted, cards]);
  
  
  

  const calculateTotalValue = (pack, foilCard) => {
    return [...pack, foilCard].reduce((sum, card) => sum + (card ? card.price || 0 : 0), 0);
  };

  const renderCardDetails = (card) => (
    <Card key={card.docName} sx={{ marginBottom: 1, padding: 1, display: "flex", alignItems: "center" }}>
      {/* Card Image */}
      <Avatar
        src={card.image_uris?.digital?.small || card.image_uris?.digital?.large}
        alt={card.name}
        variant="square"
        sx={{
          width: 50,
          height: 70,
          border: `2px solid ${inkColorMap[card.ink] || "#F2D18B"}`,
          marginRight: 2,
        }}
      />

      {/* Card Details */}
      <Box sx={{ flex: 1 }}>
        <Typography variant="body1" sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>
          {card.name}
        </Typography>

        <Typography variant="body2" color="textSecondary" sx={{ fontSize: "0.8rem" }}>
          {card.version} | #{card.collector_number}
        </Typography>

        {/* Rarity and Type Chips */}
        <Box sx={{ display: "flex", gap: 1, marginTop: 1 }}>
          <Chip
            label={rarities[card.rarity] || card.rarity}
            size="small"
            sx={{
              backgroundColor: "#0C0A5A",
              color: "white",
              fontSize: "0.7rem",
            }}
          />
          <Chip
            label={card.type}
            size="small"
            sx={{
              backgroundColor: card.type === "Foil" ? "#ffd700" : "#b0b0b0",
              color: card.type === "Foil" ? "#000000" : "#ffffff",
              fontSize: "0.7rem",
            }}
          />
        </Box>

        {/* Card Price */}
        <Typography variant="body2" sx={{ marginTop: 1, fontSize: "0.85rem", fontWeight: "bold" }}>
          {convertPrice(card.price || 0)}
        </Typography>
      </Box>
    </Card>
  );

  const renderPack = (pack, title, foilCard) => (
    <Paper sx={{ padding: 4, marginBottom: 4 }}>
      {foilCard && renderCardDetails(foilCard)}
      {pack.map(renderCardDetails)}
    </Paper>
  );

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Booster Pack Value Estimator
      </Typography>
      <FormControl fullWidth sx={{ marginBottom: 4 }}>
        <InputLabel>Select a Set</InputLabel>
        <Select
          value={selectedSet}
          onChange={(e) => {
            const selectedSet = sets.find((set) => set.id === e.target.value);
            setSelectedSet(e.target.value);
            setSelectedSetName(selectedSet ? selectedSet.name : ""); // Set the set name based on the selected ID
          }}
        >
          {sets.map((set) => (
            <MenuItem key={set.id} value={set.id}>
              {set.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        control={<Switch checked={includeEnchanted} onChange={(e) => setIncludeEnchanted(e.target.checked)} />}
        label="Include Enchanted Cards"
      />

<Grid container spacing={4}>
  <Grid item xs={12} md={6}>
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">
          Estimated Highest Pack Total:{" "}
          {convertPrice(calculateTotalValue(highestPack, highestFoilCard))}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {renderPack(highestPack, "Estimated Highest Pack", highestFoilCard)}
      </AccordionDetails>
    </Accordion>
  </Grid>
  <Grid item xs={12} md={6}>
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">
          Estimated Lowest Pack Total:{" "}
          {convertPrice(calculateTotalValue(lowestPack, lowestFoilCard))}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {renderPack(lowestPack, "Estimated Lowest Pack", lowestFoilCard)}
      </AccordionDetails>
    </Accordion>
  </Grid>
</Grid>

      <CaseBoxCalculator
        selectedSet={selectedSet}
        selectedSetName={selectedSetName}
        cards={cards}
        highestPack={combinedHighestPack || []}
        lowestPack={combinedLowestPack || []}
      />

<CaseCalculator
  cards={cards}
  highestPack={combinedHighestPack}
  lowestPack={combinedLowestPack}
  selectedSetName={selectedSetName}
/>

    </Box>
  );
};

export default BoosterPackCalculator;
