import React, { useState, useEffect, useCallback } from 'react';
import { Container, Typography, Grid, Card, CardContent, Select, MenuItem, FormControl, InputLabel, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useAuth } from '../auth';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

const PurchaseSummary = () => {
  const { currentUser } = useAuth();
  const [purchases, setPurchases] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [purchaseTotal, setPurchaseTotal] = useState(0);
  const [selectedRange, setSelectedRange] = useState('All Time');
  const [activeFilters, setActiveFilters] = useState({ set: false, type: false, rarity: false, ink: false });
  const [groupedData, setGroupedData] = useState({});

  useEffect(() => {
    const fetchPurchases = async () => {
      if (currentUser) {
        const purchasesSnapshot = await getDocs(collection(db, 'users', currentUser.uid, 'purchases'));
        const purchaseData = purchasesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPurchases(purchaseData);
      }
    };

    fetchPurchases();
  }, [currentUser]);

  const getStartAndEndDates = useCallback((range) => {
    const today = dayjs();
    switch (range) {
      case 'This Month':
        return { start: today.startOf('month'), end: today.endOf('month') };
      case 'Last Month':
        return { start: today.subtract(1, 'month').startOf('month'), end: today.subtract(1, 'month').endOf('month') };
      case 'This Week':
        return { start: today.startOf('week'), end: today.endOf('week') };
      case 'Last Week':
        return { start: today.subtract(1, 'week').startOf('week'), end: today.subtract(1, 'week').endOf('week') };
      case 'All Time':
      default:
        return { start: null, end: null };
    }
  }, []);

  const filterByDateRange = useCallback((transactionDate) => {
    if (!transactionDate) return false;

    const purchaseDate = transactionDate.toDate ? dayjs(transactionDate.toDate()) : dayjs(transactionDate);
    const { start, end } = getStartAndEndDates(selectedRange);

    return !start || !end ? true : purchaseDate.isBetween(start, end, null, '[]');
  }, [selectedRange, getStartAndEndDates]);

  const groupPurchaseData = useCallback((purchases) => {
    const groupMap = {};
    purchases.forEach(purchase => {
      purchase.items.forEach(item => {
        let groupKey = '';
        if (activeFilters.set) groupKey += `Set: ${item.setName} `;
        if (activeFilters.type) groupKey += `Type: ${item.cardType} `;
        if (activeFilters.rarity) groupKey += `Rarity: ${item.cardRarity} `;
        if (activeFilters.ink) groupKey += `Ink: ${item.cardInk} `;

        groupKey = groupKey.trim() || 'Other';

        if (!groupMap[groupKey]) {
          groupMap[groupKey] = [];
        }
        groupMap[groupKey].push(item);
      });
    });
    return groupMap;
  }, [activeFilters]);

  useEffect(() => {
    const filteredPurchases = purchases.filter(purchase => {
      const transactionDate = purchase.transactionDate;
      return filterByDateRange(transactionDate);
    });

    const totalItemsPurchased = filteredPurchases.reduce((total, purchase) => total + getTotalItems(purchase.items), 0);
    const totalPurchaseAmount = filteredPurchases.reduce((total, purchase) => total + getPurchaseTotal(purchase.items), 0);

    setTotalItems(totalItemsPurchased);
    setPurchaseTotal(totalPurchaseAmount);

    const grouped = groupPurchaseData(filteredPurchases);
    setGroupedData(grouped);
  }, [purchases, selectedRange, activeFilters, filterByDateRange, groupPurchaseData]);

  const getTotalItems = items => items.reduce((total, item) => total + item.quantity, 0);
  const getPurchaseTotal = items => items.reduce((total, item) => total + item.price * item.quantity, 0);

  const handleFilterChange = filter => {
    setActiveFilters(prev => ({ ...prev, [filter]: !prev[filter] }));
  };

  const renderTable = () => {
    if (Object.keys(groupedData).length === 0) return null;

    let totalGroupedItems = 0;
    let totalGroupedPurchases = 0;

    return (
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Group</TableCell>
              <TableCell>Total Items Purchased</TableCell>
              <TableCell>Total Purchase Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(groupedData).map(([groupKey, items]) => {
              const totalItemsForGroup = items.reduce((total, item) => total + item.quantity, 0);
              const totalPurchasesForGroup = items.reduce((total, item) => total + item.price * item.quantity, 0);

              totalGroupedItems += totalItemsForGroup;
              totalGroupedPurchases += totalPurchasesForGroup;

              return (
                <TableRow key={groupKey}>
                  <TableCell>{groupKey}</TableCell>
                  <TableCell>{totalItemsForGroup}</TableCell>
                  <TableCell>${totalPurchasesForGroup.toFixed(2)}</TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell><strong>Total</strong></TableCell>
              <TableCell><strong>{totalGroupedItems}</strong></TableCell>
              <TableCell><strong>${totalGroupedPurchases.toFixed(2)}</strong></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>My Purchase Summary</Typography>

      <FormControl fullWidth margin="normal">
        <InputLabel>Date Period</InputLabel>
        <Select value={selectedRange} onChange={(e) => setSelectedRange(e.target.value)}>
          <MenuItem value="All Time">All Time</MenuItem>
          <MenuItem value="This Month">This Month</MenuItem>
          <MenuItem value="Last Month">Last Month</MenuItem>
          <MenuItem value="This Week">This Week</MenuItem>
          <MenuItem value="Last Week">Last Week</MenuItem>
        </Select>
      </FormControl>

      <FormGroup row>
        {['set', 'type', 'rarity', 'ink'].map(filter => (
          <FormControlLabel
            key={filter}
            control={<Checkbox checked={activeFilters[filter]} onChange={() => handleFilterChange(filter)} />}
            label={filter.charAt(0).toUpperCase() + filter.slice(1)}
          />
        ))}
      </FormGroup>

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography>Total Items Purchased</Typography>
              <Typography variant="h6">{totalItems || <CircularProgress size={20} />}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography>Total Purchase Amount</Typography>
              <Typography variant="h6">${purchaseTotal.toFixed(2) || <CircularProgress size={20} />}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {renderTable()}
    </Container>
  );
};

export default PurchaseSummary;
