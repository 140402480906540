import React from 'react';
import { useSubscription } from './SubscriptionContext';
import { Box, Typography } from '@mui/material';

const FeatureControl = ({
  requiredFeatures = [],
  currentQuantity = 0,
  showMessage = true,
  children,
}) => {
  const { subscription } = useSubscription();

  // Find all matching features from the user's subscription
  const features = subscription?.features?.filter(f => requiredFeatures.includes(f.name)) || [];

  // Check if the user has any of the required features
  const hasFeature = features.length > 0;

  // Get the limit from the feature
  const featureLimit = features.length > 0 ? parseInt(features[0].limit, 10) : 0;

  // Check if there is any feature with a null limit (which means unlimited listings)
  const hasUnlimitedFeature = features.some(feature => feature.limit === null);

  // Determine if any feature has a limit and if that limit has been reached
  const isFeatureLimited = !hasUnlimitedFeature && featureLimit > 0 && currentQuantity >= featureLimit;

  // If showMessage is false, check if children is a function (render prop)
  if (!showMessage) {
    if (typeof children === 'function') {
      return children({ limit: featureLimit, isLimited: isFeatureLimited });
    }
    return <>{children}</>;
  }

  return (
    <Box position="relative" height="100%">
      {/* Render the children when feature is available or limit is not reached */}
      {hasFeature && (!isFeatureLimited || hasUnlimitedFeature) ? (
        typeof children === 'function' ? children({ limit: featureLimit, isLimited: isFeatureLimited }) : children
      ) : (
        <Box sx={{ position: 'relative', height: '100%' }}>
          {/* Render the underlying content but disable interaction using an overlay */}
          <Box sx={{ opacity: 0.5, pointerEvents: 'none', height: '100%' }}>
            {typeof children === 'function' ? children({ limit: featureLimit, isLimited: isFeatureLimited }) : children}
          </Box>

          {/* Semi-transparent overlay with a smaller and shorter message */}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              bgcolor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
              zIndex: 1, // Ensure it covers the section content
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                bgcolor: 'rgba(255, 255, 255, 0.7)', // Slightly transparent background for text
                padding: '1px', // Reduce padding
                borderRadius: '8px',
                textAlign: 'center',
                maxWidth: '80%', // Limit the message box width for responsiveness
                width: 'auto',
              }}
            >
              <Typography variant="caption" sx={{ fontWeight: 'bold' }}> {/* Use smaller text */}
                {isFeatureLimited && !hasUnlimitedFeature
                  ? `Limit Reached (${currentQuantity}/${featureLimit})`
                  : 'Paid Feature'}
              </Typography>

            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FeatureControl;
