import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Avatar,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppBarContext } from "../components/AppBarContext";
import { rarities, currencySymbols, inkColorMap } from "./constants";

const CaseBoxCalculator = ({ selectedSet, cards, highestPack, lowestPack, selectedSetName }) => {
  const { selectedCountry, exchangeRates } = useAppBarContext();

  const [maxBoxCards, setMaxBoxCards] = useState([]);
  const [maxBoxValue, setMaxBoxValue] = useState(0);
  const [minBoxCards, setMinBoxCards] = useState([]);
  const [minBoxValue, setMinBoxValue] = useState(0);

  // Utility function to convert prices based on selected currency
  const convertPrice = (usdPrice) => {
    if (!exchangeRates[selectedCountry]) return usdPrice;
    return `${selectedCountry} ${currencySymbols[selectedCountry]}${(usdPrice * exchangeRates[selectedCountry]).toFixed(2)}`;
  };

  const preselectCards = useCallback(
    (sortOrder = "desc") => {
      const foilCards = cards.filter((card) => card.type?.toLowerCase() === "foil");
      const normalCards = cards.filter((card) => card.type?.toLowerCase() === "normal");

      const sortByValue = (cardList) =>
        [...cardList].sort((a, b) =>
          sortOrder === "desc" ? (b.price || 0) - (a.price || 0) : (a.price || 0) - (b.price || 0)
        );

      return {
        normal: {
          Common: sortByValue(normalCards.filter((card) => card.rarity === "Common")),
          Uncommon: sortByValue(normalCards.filter((card) => card.rarity === "Uncommon")),
          Rare: sortByValue(normalCards.filter((card) => card.rarity === "Rare")),
          Super_rare: sortByValue(normalCards.filter((card) => card.rarity === "Super_rare")),
          Legendary: sortByValue(normalCards.filter((card) => card.rarity === "Legendary")),
          Enchanted: sortByValue(normalCards.filter((card) => card.rarity === "Enchanted")),
        },
        foil: {
          Common: sortByValue(foilCards.filter((card) => card.rarity === "Common")),
          Uncommon: sortByValue(foilCards.filter((card) => card.rarity === "Uncommon")),
          Rare: sortByValue(foilCards.filter((card) => card.rarity === "Rare")),
          Super_rare: sortByValue(foilCards.filter((card) => card.rarity === "Super_rare")),
          Legendary: sortByValue(foilCards.filter((card) => card.rarity === "Legendary")),
          Enchanted: sortByValue(foilCards.filter((card) => card.rarity === "Enchanted")),
        },
      };
    },
    [cards]
  );

  const calculateBoxValue = useCallback(
    (startingPack, sortOrder = "desc") => {
      let boxValue = 0;
      const boxDetails = [];
      const rarityCounters = {
        Common: { normal: {}, foil: {} },
        Uncommon: { normal: {}, foil: {} },
        Rare: { normal: {}, foil: {} },
        Super_rare: { normal: {}, foil: {} },
        Legendary: { normal: {}, foil: {} },
        Enchanted: { normal: {}, foil: {} },
      };

      const globalUsedCardNames = new Set();
      const boxRarityLimits = {
        normal: {
          Common: Infinity,
          Uncommon: Infinity,
          Rare: Infinity,
          Super_rare: 8,
          Legendary: 4,
          Enchanted: 1,
        },
        foil: {
          Common: Infinity,
          Uncommon: Infinity,
          Rare: 8,
          Super_rare: 2,
          Legendary: 1,
          Enchanted: 1,
        },
      };

      const preselected = preselectCards(sortOrder);

      if (startingPack && startingPack.length > 0) {
        const startingPackValue = startingPack.reduce((sum, card) => sum + (card.price || 0), 0);
        boxDetails.push({ packNumber: 1, packValue: startingPackValue, cards: startingPack });
        boxValue += startingPackValue;

        startingPack.forEach((card) => {
          const typeKey = card.type === "Foil" ? "foil" : "normal";
          rarityCounters[card.rarity][typeKey][card.name] =
            (rarityCounters[card.rarity][typeKey][card.name] || 0) + 1;
          if (boxRarityLimits[typeKey][card.rarity] !== Infinity) {
            boxRarityLimits[typeKey][card.rarity] = Math.max(
              0,
              boxRarityLimits[typeKey][card.rarity] - 1
            );
          }
        });
      }

      for (let i = 1; i < 24; i++) {
        const { pack, value } = generatePack(
          preselected,
          rarityCounters,
          globalUsedCardNames,
          boxRarityLimits
        );
        boxDetails.push({ packNumber: i + 1, cards: pack, packValue: value });
        boxValue += value;
      }

      return { boxValue, boxDetails };
    },
    [preselectCards]
  );

  const generatePack = (
    preselected,
    rarityCounters,
    globalUsedCardNames,
    boxRarityLimits
  ) => {
    const pack = [];
    let packValue = 0;

    const selectCard = (
      cardList,
      rarity,
      rarityCounters,
      globalUsedCardNames,
      pack,
      typeKey
    ) => {
      if (boxRarityLimits[typeKey][rarity] <= 0) return 0;

      let card = cardList.find(
        (c) =>
          !globalUsedCardNames.has(c.name) &&
          (rarityCounters[rarity][typeKey][c.name] || 0) <
            (typeKey === "foil" ? 1 : Infinity)
      );

      if (!card) {
        card = cardList.find(
          (c) =>
            (rarityCounters[rarity][typeKey][c.name] || 0) <
            (typeKey === "foil" ? 1 : Infinity)
        );
      }

      if (card) {
        pack.push(card);
        rarityCounters[rarity][typeKey][card.name] =
          (rarityCounters[rarity][typeKey][card.name] || 0) + 1;
        boxRarityLimits[typeKey][rarity] -= 1;

        if (!globalUsedCardNames.has(card.name)) {
          globalUsedCardNames.add(card.name);
        }
        packValue += card.price || 0;
        return card.price || 0;
      }
      return 0;
    };

    const foilRarities = ["Legendary", "Super_rare", "Rare", "Uncommon", "Common"];
    for (const rarity of foilRarities) {
      if (
        selectCard(
          preselected.foil[rarity],
          rarity,
          rarityCounters,
          globalUsedCardNames,
          pack,
          "foil"
        )
      ) {
        break;
      }
    }

    const higherRarities = ["Super_rare", "Legendary"];
    let slotsFilled = 0;
    for (let i = 0; i < 2; i++) {
      for (const rarity of higherRarities) {
        if (
          selectCard(
            preselected.normal[rarity],
            rarity,
            rarityCounters,
            globalUsedCardNames,
            pack,
            "normal"
          )
        ) {
          slotsFilled++;
          break;
        }
      }
    }

    if (slotsFilled < 2) {
      for (let i = slotsFilled; i < 2; i++) {
        selectCard(
          preselected.normal.Rare,
          "Rare",
          rarityCounters,
          globalUsedCardNames,
          pack,
          "normal"
        );
      }
    }

    for (let i = 0; i < 6; i++) {
      packValue += selectCard(
        preselected.normal.Common,
        "Common",
        rarityCounters,
        globalUsedCardNames,
        pack,
        "normal"
      );
    }

    for (let i = 0; i < 3; i++) {
      packValue += selectCard(
        preselected.normal.Uncommon,
        "Uncommon",
        rarityCounters,
        globalUsedCardNames,
        pack,
        "normal"
      );
    }

    return { pack, value: packValue };
  };

  useEffect(() => {
    const { boxValue: maxValue, boxDetails: maxDetails } = calculateBoxValue(highestPack, "desc");
    const { boxValue: minValue, boxDetails: minDetails } = calculateBoxValue(lowestPack, "asc");
  
    setMaxBoxValue(maxValue);
    setMaxBoxCards(maxDetails);
    setMinBoxValue(minValue);
    setMinBoxCards(minDetails);
  }, [calculateBoxValue, highestPack, lowestPack]);

  const renderBoxDetails = (boxDetails) => (
    <Box sx={{ marginTop: 4 }}>
      {boxDetails.map((pack, packIndex) => {
        const rarityCounts = pack.cards.reduce((acc, card) => {
          acc[card.rarity] = (acc[card.rarity] || 0) + 1;
          return acc;
        }, {});

        return (
          <Accordion key={packIndex} sx={{ marginBottom: 2 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">
                Pack {pack.packNumber} - Total Value:{" "}
                {convertPrice(pack.packValue)}
              </Typography>
              <Box sx={{ marginLeft: 2, display: "flex", gap: 1 }}>
                {Object.entries(rarityCounts).map(([rarity, count]) => (
                  <Chip
                    key={rarity}
                    label={`${rarity}: ${count}`}
                    size="small"
                    sx={{
                      backgroundColor:
                        rarity === "Legendary"
                          ? "#ffd700"
                          : rarity === "Super_rare"
                          ? "#c0c0c0"
                          : rarity === "Rare"
                          ? "#cd7f32"
                          : rarity === "Uncommon"
                          ? "#4f4f4f"
                          : rarity === "Common"
                          ? "#d3d3d3"
                          : "#b0b0b0",
                      color: rarity === "Common" ? "black" : "white",
                      fontSize: "0.8rem",
                    }}
                  />
                ))}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {pack.cards
                .sort((a, b) =>
                  a.type === "Foil" && b.type !== "Foil" ? -1 : 0
                )
                .map((card, cardIndex) => (
                  <Box
                    key={cardIndex}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 2,
                      padding: 1,
                      borderRadius: 2,
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <Avatar
                      src={
                        card.image_uris?.digital?.small ||
                        card.image_uris?.digital?.large
                      }
                      alt={card.name}
                      variant="square"
                      sx={{
                        width: 50,
                        height: 70,
                        border: `2px solid ${
                          inkColorMap[card.ink] || "#F2D18B"
                        }`,
                        marginRight: 2,
                      }}
                    />

                    <Box sx={{ flex: 1 }}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {card.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {card.version} | #{card.collector_number}
                      </Typography>
                      <Box sx={{ display: "flex", gap: 1, marginTop: 1 }}>
                        <Chip
                          label={rarities[card.rarity] || card.rarity}
                          size="small"
                          sx={{
                            backgroundColor:
                              card.rarity === "Legendary"
                                ? "#ffd700"
                                : card.rarity === "Super_rare"
                                ? "#c0c0c0"
                                : card.rarity === "Rare"
                                ? "#cd7f32"
                                : card.rarity === "Uncommon"
                                ? "#4f4f4f"
                                : card.rarity === "Common"
                                ? "#d3d3d3"
                                : "#b0b0b0",
                            color: card.rarity === "Common" ? "black" : "white",
                            fontSize: "0.7rem",
                          }}
                        />
                        <Chip
                          label={card.type}
                          size="small"
                          sx={{
                            backgroundColor:
                              card.type === "Foil" ? "#ffd700" : "#b0b0b0",
                            color: card.type === "Foil" ? "#000000" : "#ffffff",
                            fontSize: "0.7rem",
                          }}
                        />
                      </Box>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold", marginTop: 1 }}
                      >
                        {convertPrice(card.price || 0)}
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h6" gutterBottom>Set Name: {selectedSetName}</Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">
                Estimated Maximum Box Total per Case: {convertPrice(maxBoxValue)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>{renderBoxDetails(maxBoxCards)}</AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} md={6}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">
                Estimated Minimum Box Total per Case: {convertPrice(minBoxValue)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>{renderBoxDetails(minBoxCards)}</AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CaseBoxCalculator;
