import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CaseCalculator = ({ cards, selectedSetName }) => {
  const [highestProcessedCards, setHighestProcessedCards] = useState([]);
  const [lowestProcessedCards, setLowestProcessedCards] = useState([]);
  const [highestCaseValue, setHighestCaseValue] = useState(0); // Highest possible case value
  const [lowestCaseValue, setLowestCaseValue] = useState(0); // Lowest possible case value

  // Distribution rates and uniqueness constraints
  const distribution = useMemo(() => ({
    normal: {
      Common: { total: 528, unique: 60 },
      Uncommon: { total: 264, unique: 40 },
      Rare: { total: 178, unique: 30 },
      Super_rare: { total: 58, unique: 10 },
      Legendary: { total: 30, unique: 12 },
    },
    foil: {
      Common: { total: 51, unique: 51 },
      Uncommon: { total: 24, unique: 24 },
      Rare: { total: 14, unique: 14 },
      Super_rare: { total: 4, unique: 4 },
      Legendary: { total: 2, unique: 2 },
      Enchanted: { total: 1, unique: 1 },
    },
  }), []);

  // Process and filter cards based on distribution and uniqueness
  
  useEffect(() => {
    if (cards.length === 0) return;

    const processCards = (type, criteria, sortOrder = "desc") => {
      return Object.entries(criteria).map(([rarity, { total, unique }]) => {
        // Filter and sort cards by rarity and type
        const filtered = cards
          .filter(
            (card) =>
              card.type?.toLowerCase() === type && card.rarity === rarity
          )
          .sort((a, b) =>
            sortOrder === "desc"
              ? (b.price || 0) - (a.price || 0)
              : (a.price || 0) - (b.price || 0)
          )
          .slice(0, unique); // Limit to unique cards

        // Calculate the number of copies for each card
        const distributedCards = [];
        let totalDistributed = 0;

        filtered.forEach((card) => {
          if (totalDistributed >= total) return; // Stop if we've reached the total
          const remaining = total - totalDistributed;
          const count = Math.min(remaining, Math.ceil(total / unique));
          distributedCards.push(...Array(count).fill(card));
          totalDistributed += count;
        });

        return { rarity, cards: distributedCards };
      });
    };

    const normalCardsHighest = processCards("normal", distribution.normal, "desc");
    const foilCardsHighest = processCards("foil", distribution.foil, "desc");

    const normalCardsLowest = processCards("normal", distribution.normal, "asc");
    const foilCardsLowest = processCards("foil", distribution.foil, "asc");

    const calculateCaseValue = (processedData) =>
      processedData
        .flatMap((rarityData) => rarityData.cards)
        .reduce((sum, card) => sum + (card.price || 0), 0);

    setHighestCaseValue(calculateCaseValue([...normalCardsHighest, ...foilCardsHighest]));
    setLowestCaseValue(calculateCaseValue([...normalCardsLowest, ...foilCardsLowest]));

    setHighestProcessedCards([
      { type: "Normal", data: normalCardsHighest },
      { type: "Foil", data: foilCardsHighest },
    ]);

    setLowestProcessedCards([
      { type: "Normal", data: normalCardsLowest },
      { type: "Foil", data: foilCardsLowest },
    ]);
  }, [cards, distribution]);

    return (
        <Box sx={{ padding: 4 }}>
          <Typography variant="h5">
            {selectedSetName} - Case Calculator
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Accordion defaultExpanded={false}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">
                    Highest Estimated Case Value: ${highestCaseValue.toFixed(2)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {highestProcessedCards.map((typeData, index) => (
                    <Box key={index}>
                      <Typography variant="h6">{typeData.type} Cards</Typography>
                      {typeData.data.map((rarityData) => (
                        <Typography key={rarityData.rarity} variant="body2">
                          {rarityData.rarity}: {rarityData.cards.length} cards
                        </Typography>
                      ))}
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12} md={6}>
              <Accordion defaultExpanded={false}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">
                    Lowest Estimated Case Value: ${lowestCaseValue.toFixed(2)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {lowestProcessedCards.map((typeData, index) => (
                    <Box key={index}>
                      <Typography variant="h6">{typeData.type} Cards</Typography>
                      {typeData.data.map((rarityData) => (
                        <Typography key={rarityData.rarity} variant="body2">
                          {rarityData.rarity}: {rarityData.cards.length} cards
                        </Typography>
                      ))}
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Box>
      );
    };

export default CaseCalculator;
